<template>
  <div>
    {{turismoWorkspace}}
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('app',['locale', 'turismoWorkspace']),
  },
}
</script>

